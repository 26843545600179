@import "../../styles/variables";
@import "../../styles/functions";

.status-card {
  height: 14rem;
  background-color: $green;
  border-radius: 1.4rem;
  margin-top: 2rem;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 12px 15px 15px -9px rgba(77, 89, 69, 0.5);
  -webkit-box-shadow: 12px 15px 15px -9px rgba(77, 89, 69, 0.5);
  -moz-box-shadow: 12px 15px 15px -9px rgba(77, 89, 69, 0.5);

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .weather {
      display: flex;
      flex-direction: column;
      color: $cream;
      &_icon {
        font-size: 2.6rem;
      }
      &_description {
        margin-top: calcRem(20px);
        font-size: $fontSizeExtraSmall;
      }
    }
    .green-score {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &_title {
        color: $cream;
        font-size: $fontSizeSmall;
        font-weight: $regular;
        margin: 0;
      }
      &_number {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 1rem;
        margin-top: calcRem(20px);
        color: $green;
        background-color: $cream;
      }
    }
  }
  .bottom {
    color: $cream;
    justify-content: baseline;
    .location {
      margin: 0;
      padding-top: 0.5rem;
    }
  }
}
