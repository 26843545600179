@import "variables";
@import "functions";
@import "global";
@import "~bootstrap/scss/bootstrap";


.location-section {
  margin-top: calcRem(50px);

  .location-row {
    display: flex;
  }
}


.alert-section {
  margin-top: calcRem(50px);
  .alert-top {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include media-breakpoint-down (md) {
      flex-direction: column;
    }
  }
  .priority {
    font-size: $fontSizeExtraSmall;
    opacity: 0.3;
  }
  .alert-row {
    display: flex;
  }
}



.savings-section {
  margin-top: calcRem(50px);
  &__row {
    display: flex;
    flex-wrap: wrap;
  }
  &__card {
    margin-right: 3rem;
  }
}

.performance-section {
  margin-top: calcRem(50px);
  &__title {
    display: flex;
    justify-content: space-between;
  }
}

