$cream: #faefdf;
$lightcream: #fff7eb;
$brown: #826d5e;
$green: #4d5945;
$midgreen: #B4C0AC;
$lightgreen: #d6decf;
$brightgreen:#f6fff0;
$yellow: #fdeca2;
$lightyellow: #f9f0c9;
$white: #fff;

$fontJosefinSans: 'Josefin Sans';
$fontOswald: 'Oswald';

$thin: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$fontSizeExtraExtraSmall: 7px;
$fontSizeExtraSmall: 14px;
$fontSizeSmall: 18px;
$fontSizeMedium: 22px;
$fontSizeSemiLarge: 26px;
$fontSizeLarge: 30px;
$fontSizeExtraLarge: 34px;
