a {
  color: $brown!important;
  text-decoration: none!important;
  cursor: pointer;
}

body, html { overflow-x:hidden; }

h1, h2, h3, h4, h5, p {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.list-group-item {
  border-color: $brown!important;
  padding-bottom: calcRem(20px)!important;
  padding-top: calcRem(20px)!important;
}
