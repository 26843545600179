@import "../../styles/variables";
@import "../../styles/functions";
@import "../../styles/global";

.alert-card {
  display: flex;
  height: 8.5rem;
  margin-top: 2rem;
  background-color: $cream;
  border-radius: 1.4rem;
  box-shadow: 12px 15px 15px -9px rgba(25, 26, 25, 0.2);
  -webkit-box-shadow: 12px 15px 15px -9px rgba(25, 26, 25, 0.2);
  -moz-box-shadow: 12px 15px 15px -9px rgba(25, 26, 25, 0.2);
  .side-bar {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-top-left-radius: 1.4rem;
    border-bottom-left-radius: 1.4rem;
    color: $cream;
  }
  &_data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    &_left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      &-top {
        padding: 0;
        .title {
          margin: 0;
          font-size: $fontSizeSmall;
        }
      }
      &-bottom {
        padding-top: 1rem;
        font-size: .8rem;
      }
    }
    &_right {
      &-top {
        display: flex;
        flex-direction: row;
        .temperature-icon {
          padding-right: 1rem;
          height: calcRem(45px);
        }
        .temperature-alert {
          font-weight: $semibold;
          font-size: .8rem;
        }
      }
      &-bottom {
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
        .time-icon {
          padding-right: 0.4rem;
        }
        .date-time {
          display: flex;
          font-weight: $semibold;
          font-size: .8rem;
          .date {
            margin-right: .5rem;
          }
        }
      }
    }
  }
}
