@import "../../styles/variables";
@import "../../styles/functions";
@import "~bootstrap/scss/bootstrap";

.navigation-block {
  background-color: $cream;
  border-right: thick solid $white;
}

.content-block {
  background-color: $cream;
  min-height: 100vh;
  @include media-breakpoint-down (lg) {
    padding-bottom: calcRem(150px);
  }

  .master-logo {
    @include media-breakpoint-down (lg) {
      text-align: center;
      margin-bottom: calcRem(20px);
    }
  }
}

