
@import '../../styles/variables';

.center-me {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.green-savings-card {
  width: 15rem;
  height: 20rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: $midgreen;
  border-radius: 1.4rem;
  padding: 2rem;
  box-shadow: 12px 15px 15px -9px rgba(25, 26, 25, 0.2);
  -webkit-box-shadow: 12px 15px 15px -9px rgba(25, 26, 25, 0.2);
  -moz-box-shadow: 12px 15px 15px -9px rgba(25, 26, 25, 0.2);
  &_title {
    color: $green;
    text-align: center;
  }
  &_bottom-left {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    color: $brightgreen;
    font-weight: $bold;
    padding-left: 1rem;
    padding-bottom: 1rem;
    .coins-icon {
      width: 2rem;
      height: 2rem;
    }
    .comment {
      padding-top: .2rem;
      font-size: $fontSizeExtraSmall;
    }
  }
  &_bottom-right {
    width: 6rem ;
    height: 3.5rem;
    background-color: $green;
    color: $lightgreen;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 1.4rem;
    border-bottom-right-radius: 1.4rem;
    .details-cta {
      padding-right: .3rem;
    }
  }
}
