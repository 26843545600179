@import '../../styles/variables';
@import '../../styles/functions';
@import "~bootstrap/scss/bootstrap";

.menu {
  padding: calcRem(20px);
  @include media-breakpoint-down (lg) {
    position: fixed;
    bottom: 1.875rem;
    left: 50%;
    right: 0;
    width: 95vw;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #fff;
    border-radius: 3.125rem;
    z-index: 99999;
    transform: translateX(-50%);
  }

  &__list {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down (lg) {
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0;
    }

    &__link {
      padding: calcRem(10px);
      @include media-breakpoint-down (lg) {
        padding: calcRem(5px) 0;
        width: calcRem(50px);
      }
      &-active{
        font-weight: 900;

        svg {
          fill: $brown;
          stroke-width: calcRem(1px);
          stroke: $brown;
        }
      }

      &__item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        @include media-breakpoint-down (lg) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: calcRem(15px);
        }
        &__text {
          padding-left: calcRem(10px);
          line-height: calcRem(40px);
          padding-top: calcRem(5px);
          @include media-breakpoint-down (lg) {
            font-size: $fontSizeExtraExtraSmall;
            padding: 0;
            line-height: calcRem(35px);
          }
        }
      }
    }
  }
}


