@import '../../styles/variables';

.App {
  font-family: $fontJosefinSans;
  color: $brown;
}






