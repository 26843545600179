@import "~bootstrap/scss/bootstrap";

@function calcRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calcRem($size);
}
