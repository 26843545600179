@import '../../styles/variables';
@import '../../styles/functions';

.welcome-message-box {
  color: $cream;
  background-color: $brown;
  border-radius: 1.4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: calcRem(20px) calcRem(40px) calcRem(20px) calcRem(30px);
  box-shadow: 14px 15px 5px -14px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 14px 15px 5px -14px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 14px 15px 5px -14px rgba(0, 0, 0, 0.08);

  .welcome-header {
    font-size: $fontSizeMedium;
    font-weight: $regular;
    margin: 0;
    padding-bottom: 0.7rem;
  }
  .welcome-description {
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
  }
  .boxed-logo {
    background-color: $cream;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 1.4rem;
    border-bottom-right-radius: 1.4rem;
  }
}
