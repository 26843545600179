@import "../../styles/variables";
@import "../../styles/functions";
@import "../../styles/global";

.performance-bar {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: calcRem(20px);

  @include media-breakpoint-only (xs) {
    width: calcRem(160px);
    padding-right: 0;
  }

  &-wrapper {
    justify-content: center;
    display: flex;
    @include media-breakpoint-only (xs) {
      padding: 0 !important;
      width: calcRem(160px) !important;
    }
  }

  &__title {
    font-weight: $thin;
    font-size: $fontSizeMedium;
    white-space: nowrap;
    @include media-breakpoint-only (xs) {
      font-size: $fontSizeSmall;
      font-weight: 500;
      letter-spacing: calcRem(-1px);
    }
  }
  &__box {
    //height: 18rem;
    background-color: $brown;
    //width: 80%;
    border-radius: 1.4rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    box-shadow: 12px 15px 15px -9px rgba(25, 26, 25, 0.2);
    -webkit-box-shadow: 12px 15px 15px -9px rgba(25, 26, 25, 0.2);
    -moz-box-shadow: 12px 15px 15px -9px rgba(25, 26, 25, 0.2);

    &__icon {
      @include media-breakpoint-only (xs) {
        height: calcRem(30px);
      }
    }
  }
  &__footer {
    padding-top: 0.6rem;
    text-align: center;
  }
  &__value {
    font-size: $fontSizeSmall;
    @include media-breakpoint-only (xs) {
      font-size: $fontSizeExtraSmall;
    }
  }
}
